import {ref} from '@vue/composition-api';
import store from '@/store/index';

const useExecutionPublicWorksPg = () => {
  // base data
  const executionPublicWorksPg = ref (null);
  const loading = ref (false);

  // methods
  const getExecutionPublicWorksPg = async () => {
    loading.value = true;
    const {data, status} = await store.dispatch ('fetchExecutionPublicWorksPg');
    loading.value = false;
    if (status != 200) return;
    executionPublicWorksPg.value = data;
    return data;
  };

  const updateExecutionPublicWorksPg = async executionPublicWorksPg => {
    loading.value = true;
    const {data, status} = await store.dispatch ('updateExecutionPublicWorksPg', executionPublicWorksPg);
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  };

  return {
    executionPublicWorksPg,
    loading,
    // methods
    getExecutionPublicWorksPg,
    updateExecutionPublicWorksPg,
  };
};

export default useExecutionPublicWorksPg;
