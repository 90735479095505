<template>
  <v-card>
    <ExecutionPublicWorksPgHeader></ExecutionPublicWorksPgHeader>
    <section class="form-full-width">
      <FormExecutionPublicWorksPg :loading="loading" :getExecutionPublicWorksPg="getExecutionPublicWorksPg" @beforeUpdate="updateExecutionPublicWorksPg"></FormExecutionPublicWorksPg>
    </section>
  </v-card>
</template>

<script>
import FormExecutionPublicWorksPg from '@/components/forms/FormExecutionPublicWorksPg.vue'
import ExecutionPublicWorksPgHeader from './ExecutionPublicWorksPgHeader.vue'
import useExecutionPublicWorksPg from '@/composables/useExecutionPublicWorksPg'

export default {
  components: {
    FormExecutionPublicWorksPg,
    ExecutionPublicWorksPgHeader,
  },
  setup() {
    // Composition API
    const {
      executionPublicWorksPg,
      loading,
      // methods
      getExecutionPublicWorksPg,
      updateExecutionPublicWorksPg,
    } = useExecutionPublicWorksPg()

    return {
      executionPublicWorksPg,
      loading,
      // methods
      getExecutionPublicWorksPg,
      updateExecutionPublicWorksPg,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
